import type { ReactNode } from 'react';
import clsx from 'clsx';

import Flex from '../Flex';

type Gap = 'large' | 'medium';

type Props = {
  children: ReactNode;
  className?: string;
  gap?: Gap;
};

const columnGapMap = {
  large: 'narrow-x:gap-28 gap-20',
  medium: 'narrow-x:gap-14 gap-6'
};

const Column = ({ children, className, gap = 'large' }: Props) => (
  <Flex className={clsx('flex-col', className, columnGapMap[gap])}>
    {children}
  </Flex>
);

export default Column;
