import type { LogosGridProps } from './LogosGrid';

export const watchContentProvidersHeading = 'Where to Watch';

export const watchContentProvidersSubheading =
  'Available on Your TV, Mobile Device, Tablet, or Computer';

export const watchContentProvidersText =
  'From the big screen to the palm of your hand. Outside TV is available across a wide variety of devices. Catch films, series, live events, and more - anytime, anywhere.';

export const watchAppsList: LogosGridProps = {
  heading: 'Get the Outside TV App',
  logos: [
    {
      href: 'https://apps.apple.com/us/app/outside-watch/id1187134756',
      id: 1,
      image: {
        src: '/watch-content-providers/apple-tv.svg',
        width: 49
      },
      title: 'AppleTV'
    },
    {
      href: 'https://apps.apple.com/us/app/outside-watch/id1187134756',
      id: 2,
      image: {
        src: '/watch-content-providers/ios.svg',
        width: 45
      },
      title: 'iOS'
    },
    {
      href: 'https://play.google.com/store/apps/details?id=com.maz.combo69&hl=en_US&gl=US',
      id: 3,
      image: {
        src: '/watch-content-providers/android.svg',
        width: 103
      },
      title: 'android'
    },
    {
      href: 'https://play.google.com/store/apps/details?id=com.maz.combo69&hl=en_US&gl=US',
      id: 4,
      image: {
        src: '/watch-content-providers/android-tv.svg',
        width: 93
      },
      title: 'androidTV'
    },
    {
      href: 'https://www.amazon.com/MAZ-Digital-Inc-Outside-Features/dp/B01N0TG0H8',
      id: 5,
      image: {
        src: '/watch-content-providers/fire-tv.svg',
        width: 53
      },
      title: 'fireTV'
    },
    {
      href: 'https://www.amazon.com/MAZ-Digital-Inc-Outside-Features/dp/B01N0TG0H8',
      id: 6,
      image: {
        src: '/watch-content-providers/amazon-appstore.svg',
        width: 129
      },
      title: 'Amazon AppStore'
    },
    {
      href: 'https://channelstore.roku.com/details/1e731702e7d9401eb1e7c0124ea8fa45/outside-watch',
      id: 7,
      image: {
        src: '/watch-content-providers/roku.svg',
        width: 53
      },
      title: 'Roku'
    },
    {
      id: 8,
      image: {
        src: '/watch-content-providers/vizio.svg',
        width: 97
      },
      title: 'Vizio Smartcast'
    },
    {
      id: 9,
      image: {
        src: '/watch-content-providers/samsung.svg',
        width: 103
      },
      title: 'Samsung'
    },
    {
      href: 'https://us.lgappstv.com/main/tvapp',
      id: 10,
      image: {
        src: '/watch-content-providers/lg.svg',
        width: 58
      },
      title: 'LG'
    }
  ]
};

export const watchLiveChannelList: LogosGridProps = {
  heading: 'Watch Our Free 24/7 Live Channel',
  logos: [
    {
      href: 'https://www.samsung.com/us/tvs/smart-tv/samsung-tv-plus-and-smart-hub/',
      id: 1,
      image: {
        src: '/watch-content-providers/samsung-tv-plus.svg',
        width: 37
      },
      title: 'SamsungTV Plus'
    },
    {
      href: 'https://www.vizio.com/en/watchfreeplus-explore',
      id: 2,
      image: {
        src: '/watch-content-providers/vizio-watch-free-plus.svg',
        width: 37
      },
      title: 'WatchFree+'
    },
    {
      href: 'https://play.xumo.com/networks/outside/99991189',
      id: 3,
      image: {
        src: '/watch-content-providers/xumo.svg',
        width: 81
      },
      title: 'Xumo'
    },
    {
      href: 'https://www.philo.com/login/subscribe?redirect_uri=https%3A%2F%2Fwww.philo.com%2Fplayer',
      id: 4,
      image: {
        src: '/watch-content-providers/philo.svg',
        width: 65
      },
      title: 'Philo'
    },
    {
      href: 'https://www.sling.com/channels/outsidetv-features-channel',
      id: 5,
      image: {
        src: '/watch-content-providers/sling.svg',
        width: 61
      },
      title: 'Sling'
    },
    {
      id: 6,
      image: {
        src: '/watch-content-providers/nuvyyo.svg',
        width: 127
      },
      title: 'Nuvyyo'
    },
    {
      href: 'https://planetdish.com/sling-freestream/',
      id: 7,
      image: {
        src: '/watch-content-providers/dish.svg',
        width: 63
      },
      title: 'Dish'
    },
    {
      id: 8,
      image: {
        src: '/watch-content-providers/youtube-tv.svg',
        width: 99
      },
      title: 'YoutubeTV'
    },
    {
      href: 'https://www.fubo.tv/welcome/channel/outside',
      id: 9,
      image: {
        src: '/watch-content-providers/fubo.svg',
        width: 55
      },
      title: 'FuboTV'
    },
    {
      href: 'https://www.redbox.com/stream-free-live-tv?channel=outside_tv',
      id: 10,
      image: {
        src: '/watch-content-providers/redbox.svg',
        width: 77
      },
      title: 'Redbox'
    },
    {
      id: 11,
      image: {
        src: '/watch-content-providers/freevee.svg',
        width: 81
      },
      title: 'Freevee'
    },
    {
      id: 12,
      image: {
        src: '/watch-content-providers/tubi.svg',
        width: 59
      },
      title: 'Tubi'
    },
    {
      href: 'https://www.distro.tv/live/outside-tv/',
      id: 13,
      image: {
        src: '/watch-content-providers/distro-tv.svg',
        width: 77
      },
      title: 'DistroTV'
    },
    {
      id: 14,
      image: {
        src: '/watch-content-providers/roku-channel.svg',
        width: 62
      },
      title: 'The Roku Channel'
    }
  ]
};
