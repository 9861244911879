import type { ImgHTMLAttributes } from 'react';

type Logo = {
  href?: string;
  id: number;
  image: Required<Pick<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'width'>>;
  title: string;
};

type RenderLogoProps = Omit<Logo, 'href' | 'id'>;

const renderLogo = ({ image, title }: RenderLogoProps) => (
  <div className="flex items-center justify-center h-[69px] p-3 bg-black rounded shadow-2xl wide-x:w-full wide-x:h-[66px]">
    <img {...image} alt={title} loading="lazy" />
  </div>
);

export type LogosGridProps = {
  heading: string;
  logos: Logo[];
};

const LogosGrid = ({ heading, logos }: LogosGridProps) => (
  <div className="wide-x:w-full wide-x:justify-self-end">
    <div className="text-white text-center font-plus-jakarta-sans text-16 mb-6">
      {heading}
    </div>
    <ul className="grid grid-cols-2 wide:grid-cols-4 wide-xxxx:grid-cols-6 gap-4 narrow-x:gap-6">
      {logos.map(({ href, id, ...logo }) => (
        <li key={id}>
          {href ? (
            <a href={href} rel="noreferrer" target="_blank" title={logo.title}>
              {renderLogo(logo)}
            </a>
          ) : (
            renderLogo(logo)
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default LogosGrid;
