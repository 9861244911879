import Image from 'next/image';
import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import { useMarketing } from 'apis/marketing/queries';
import Button from 'components/02-molecules/Button';
import Container from 'components/01-atoms/Container';
import Heading from 'components/01-atoms/Heading';
import clsx from 'clsx';
import type { GetMarketingResponse } from 'apis/marketing/types';
import { getOPlusPageURL, getSignUpURL } from 'helpers/utils/accountsUrls';
import { useUserType } from 'helpers/hooks/useUserType';

type Variant = 'primary' | 'secondary';

type Props = {
  marketing: GetMarketingResponse;
  variant?: Variant;
};

const MarketingCTAGate = (props) => {
  const { data, isError, isLoading } = useMarketing();

  if (isLoading && !data) return null;
  // We don't need necessarily do any loading state here since marketing is always at the bottom of the page

  if (isError) return null;
  // TODO: Add error message for marketing CTA section

  return <MarketingCTA {...props} marketing={data} />;
};

const MarketingCTA = ({ marketing, variant = 'primary' }: Props) => {
  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow-x');
  const { isLoggedIn } = useUserType();

  const { cta } = marketing;

  const { buttonText, description, headline, posters } = cta;

  const shouldDisplayPosters = Boolean(posters.length && !isMobile);

  return (
    <section
      className={clsx('w-full py-18 overflow-hidden', {
        'bg-black': variant === 'secondary',
        'bg-new-neutral-900': variant === 'primary'
      })}
    >
      <Container
        classes="flex justify-between gap-30 wide-x:gap-60"
        maxSize="x-large"
      >
        <div className="flex flex-col w-full narrow-x:w-1/2 items-start justify-center wide-x:max-w-[652px]">
          <Heading
            classes="text-network-outside-yellow mb-3 uppercase"
            level={6}
            text="watch our originals"
            variant="heading8"
          />
          <Heading
            classes="text-white capitalize mb-8"
            level={1}
            responsiveVariant="large"
            text={headline}
          />
          <p className="text-white font-plus-jakarta-sans text-16 font-light mb-8">
            {description}
          </p>
          <Button
            analytics={{
              name: buttonText,
              placement: 'Marketing CTA'
            }}
            as="link"
            href={isLoggedIn ? getOPlusPageURL(true) : getSignUpURL(true)}
            text={buttonText}
            variant="contained"
          />
        </div>
        {shouldDisplayPosters && (
          <div className="relative h-[416px] wide-xxxx:h-auto w-1/2 wide-xxxx:w-full">
            <div className="absolute wide-xxxx:static flex justify-end gap-6 wide-xxxx:w-full wide-xxxx:mr-[-25%]">
              {posters.map(({ image, imageAltTag }) => (
                <div
                  key={imageAltTag}
                  className="relative w-[316px] wide-xxxx:w-2/5 wide-xxxx:max-w-[427px] ultrawide:max-w-[553px] h-auto rounded overflow-hidden"
                >
                  <Image
                    alt={imageAltTag}
                    className="w-full"
                    height={416}
                    src={image}
                    width={316}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Container>
    </section>
  );
};

export default MarketingCTAGate;
